/**
 * Class which attaches useful browser classes to the documentElement
 * @package humans/frontend
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class BrowserClasses {
    /**
     * Attaches useful classes to the document
     */
    constructor() {
        window.browser = {
            // isIos: this.detectIos(),
            // isAndroid: /Android/i.test(navigator.userAgent),
            // isMobile: /iPhone|iPod|iPad|Android|BlackBerry|Opera Mini|IEMobile|WindowsPhone/.test(navigator.userAgent),
            // isIE: this.detectIE(),
            // isEdge: this.detectEdge(),
            // isSafari: this.detectSafari(),
            // isFirefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
            // isChrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
            isTouchDevice: this.detectTouchDevice(),
            supportsIntersectionObserver: this.supportsIntersectionObserver(),
        };

        // Add some additional classes
        const documentClasses = [
            'js',
            // window.browser.isIos                        ? 'is-ios'                : 'no-ios',
            // window.browser.isAndroid                    ? 'is-android'            : 'no-android',
            // window.browser.isMobile                     ? 'is-mobile'             : 'no-mobile',
            // window.browser.isTouchDevice                ? 'is-touch'              : 'no-touch',
            // window.browser.isIE                         ? 'is-ie'                 : 'no-ie',
            // window.browser.isEdge                       ? 'is-edge'               : 'no-edge',
            // window.browser.isFirefox                    ? 'is-firefox'            : 'no-firefox',
            // window.browser.isSafari                     ? 'is-safari'             : 'no-safari',
            // window.browser.isChrome                     ? 'is-chrome'             : 'no-chrome',
            // window.browser.supportsIntersectionObserver ? 'intersection-observer' : 'no-intersection-observer',
        ];

        // Attach browser classes
        if (document.documentElement.classList && document.documentElement.classList.add) {
            documentClasses.forEach((cls) => {
                document.documentElement.classList.add(cls);
            });
        } else {
            document.documentElement.className = document.documentElement.className + ' ' + documentClasses.join(' ');
        }
    }

    /**
     * Indicates if browser is safari
     * @return {boolean} True, if browser is safari
    detectSafari() {
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1
            && navigator.userAgent && !navigator.userAgent.match('CriOS');
    }*/

    /**
     * Indicates if browser is safari
     * @return {boolean} True, if browser is safari
    detectIos() {
        return (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
            !window.MSStream;
    }*/

    /**
     * Detects the Microsoft Edge browser version
     * @returns {number|boolean} False, if browser is not an MS Edge. The version number, if browser is MS Edge.
    detectEdge() {
        let userAgent = window.navigator.userAgent;
        let edge = userAgent.indexOf('Edge/');
        if (edge > 0) {
            // IE 12 => return version number
            return parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10);
        }

        return false;
    }*/

    /**
     * Detects the IE browser version
     * @returns {number|boolean} False, if browser is not an IE. The version number, if browser is IE.
    detectIE() {
        let userAgent = window.navigator.userAgent;
        let msie = userAgent.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10);
        }

        let trident = userAgent.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            let versionNumber = userAgent.indexOf('rv:');

            return parseInt(userAgent.substring(versionNumber + 3, userAgent.indexOf('.', versionNumber)), 10);
        }

        let edge = userAgent.indexOf('Edge/');
        if (edge > 0) {
            // IE 12 => return version number
            return parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }*/

    /**
     * Helper function, to indicate if we have a touch device
     * @return {Boolean} True, if it is a touch device
     */
    detectTouchDevice() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }

    /**
     * Indicates, if browser supports intersection observer
     * @return {boolean} True, if the browser support the intersection observer
     */
    supportsIntersectionObserver() {
        return 'IntersectionObserver' in window;
    }
}
