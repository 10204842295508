import HumansUiComponent from '../humans/frontend/ui-component';

/**
 * Breakpoint related component
 * @package humans/frontend
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class Breakpoint extends HumansUiComponent {

    /**
     * Save name of CSS breakpoint for comparison
     * @type {String}
     */
    previousBreakpoint = null;

    /**
     * Save current breakpoint in var to prevent content reflows through getComputedStyle
     * @type {String}
     */
    currentBreakpoint = null;

    /**
     * @inheritdoc
     */
    init() {
        this.previousBreakpoint = this.breakpoint;
    }

    /**
     * Binds the necessary events
     */
    registerEvents() {
        const throttledRefresh = this.throttle(this.refresh.bind(this), 200);
        this.win.on('resize', throttledRefresh);
    }

    /**
     * Update breakpoint and detect change in breakpoint
     * @example
     * this.win.on('breakpointchanged', (event, current, previous) => {
     *     this.log(`Breakpoint has changed from "${previous}" to "${current}"`);
     * });
     */
    refresh() {
        const currentBreakpoint = this.getBreakpoint(true);

        // If breakpoint has changed
        if (currentBreakpoint !== this.previousBreakpoint) {

            // Trigger global 'breakpointchanged' event
            this.win.trigger('breakpointchanged', [currentBreakpoint, this.previousBreakpoint]);

            // Store
            this.previousBreakpoint = currentBreakpoint;
        }
    }

    /**
     * Check property breakpoint via getter function
     * @param {string} name Name of breakpoint to compare
     * @returns {boolean} If breakpoint name matches
     */
    is(name) {
        return this.getBreakpoint() === name;
    }

    /**
     * Indicates if viewport is mobile viewport
     * @return {boolean} True, if it mobile viewport
     */
    get isSM() {
        return this.getBreakpoint() === 'sm';
    }

    /**
     * Indicates if viewport is Decent
     * @return {boolean} True, if viewport is md
     */
    get isMD() {
        return (this.getBreakpoint() === 'md');
    }

    /**
     * Indicates if viewport is Grid M
     * @return {boolean} True, if viewport is lg
     */
    get isLG() {
        return this.getBreakpoint() === 'lg';
    }

    /**
     * Getter for current breakpoint
     * Read CSS breakpoint stored in 'body:before'
     * @see https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
     *
     * @param {boolean=} recalc Recalculate breakpoint
     * @returns {string} Name of current breakpoint
     */
    getBreakpoint(recalc = false) {
        // Use value from var if no recalc is required
        if (this.currentBreakpoint && !recalc) {
            return this.currentBreakpoint;
        }

        // Get pseudo element 'body:before'
        const pseudoElement = this.getPseudoElement();

        // Get content from pseudo element
        const name = pseudoElement.getPropertyValue('content');

        // Remove single and double quotes for browser compatibility
        this.currentBreakpoint = name.replace(/['"]+/g, '');

        return this.currentBreakpoint;
    }
}