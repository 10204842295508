import HumansUiComponent from '../humans/frontend/ui-component';
import HumansIntersect from '../humans/frontend/intersect';

/**
 * Module: Site
 * @package humans/frontend
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class Site extends HumansUiComponent {
    /**
     * @inheritdoc
     */
    ui = {
        // Intersect related values
        intersect: '.js-intersect',

        // Anchor links
        anchorLink: '.js-anchor-link, .js-main-nav a',

        // Anchor links
        sliderBox: '.js-slider-box',
        sliderSlide: '.js-slider-slide',

        // card
        card: '.js-card',
    };

    /**
     * Breakpoint reference
     * @type {Breakpoint}
     */
    breakpoint = null;

    /**
     * @inheritdoc
     */
    init() {
        this.breakpoint = this.main.getContainer('schekorr.breakpoint');
    }

    /**
     * Indicates if site is rendering
     * @type {boolean}
     */
    isRendering = false;

    /**
     * Current scroll top position
     * @type {Number}
     */
    scrollTop = 0;

    /**
     * Indicates if there is an anchor scroll animation happening at the time
     * We need this for header animations on desktop
     * @type {boolean}
     */
    isAnchorScrolling = false;

    /**
     * Indicates if there is an anchor scroll animation timeout
     * @type {number|null}
     */
    isAnchorScrollingTimeout = null;

    /**
     * Humans Intersect reference
     * @type {HumansIntersect|Intersect}
     */
    intersect = null;

    /**
     * Scrolling timeout to indicate scrolling is over
     * @type {number|null}
     */
    scrollingTimeout = null;

    /**
     * Returns the height of the window
     * @return {number} The height of the window
     */
    get winH() {
        return this.common ? this.common.winHeight : window.innerHeight;
    }

    /**
     * @inheritdoc
     */
    registerEvents() {
        // We won't use cash dom for scroll listener because it does not yet support passive listener
        this.win.get(0).addEventListener('scroll', this.onScroll.bind(this), {passive: true});

        // Resize event
        this.window.on('resize', this.onWindowResize.bind(this));

        // Orientationchange event
        this.window.on('orientationchange', this.onWindowResize.bind(this));

        // Anchor links
        this.body.on('click', this.sel('anchorLink'), this.onAnchorNavClick.bind(this));

        // Slider box
        this.body.on('click', this.sel('sliderBox'), this.onSliderBoxClick.bind(this));
        this.body.on('touchstart', this.sel('sliderBox'), this.onSliderBoxTouch.bind(this));
        this.body.on('mouseenter', this.sel('card'), this.onCardMouseenter.bind(this));
        this.body.on('mousemove', this.sel('card'), this.onCardMousemove.bind(this));
        this.body.on('mouseleave', this.sel('card'), this.onCardMouseleave.bind(this));

        // Scroll to anchor with animation
        this.subscribe('anchor.scroll', (topic, data) => {
            this.scrollToAnchor(data.target, data.scrollOffset);
        });

        // Check anchor scrolling
        this.subscribe('site.anchorScrolling', (topic, data) => {
            const state = data ?? false;

            // Add timeout if setting back to false
            if (state) {
                if (this.isAnchorScrollingTimeout) {
                    window.clearTimeout(this.isAnchorScrollingTimeout);
                }
                this.isAnchorScrolling = state;
            } else {
                this.isAnchorScrollingTimeout = setTimeout(() => {
                    this.isAnchorScrolling = state;
                }, 1);
            }
        });
    }

    /**
     * @inheritdoc
     */
    onReady() {
        // Add intersect effect
        if (this.$('intersect').length > 0) {
            this.intersect = new HumansIntersect(this.ui.intersect, {}, this.onIntersect.bind(this));
        }

        // Perform first render
        this.render();
    }

    /**
     * @inheritdoc
     */
    onLoad() {
        // Do something onLoad
    }

    /**
     * @inheritdoc
     */
    onDomUpdate(mutations) {
        if (mutations.length) {
            // Add intersect effect for new elements
            if (this.intersect) {
                this.intersect.update();
            } else if (this.$('intersect').length > 0) {
                this.intersect = new HumansIntersect(this.ui.intersect, {}, this.onIntersect.bind(this));
            }
        }
    }

    /**
     * Handles the on scroll event on the document element
     * @param {Event} event The scroll event
     */
    onScroll(event) {
        if (!this.isRendering) {
            window.requestAnimationFrame(this.render.bind(this));
            this.isRendering = true;

            if (this.scrollingTimeout) {
                clearTimeout(this.scrollingTimeout);
            }

            this.scrollingTimeout = setTimeout(() => {
                this.publish('site.anchorScrolling', false);
            }, 100);
        }
    }

    /**
     * Handles the event, when a anchor nav item is clicked
     * @param {Event} event The click event
     */
    onAnchorNavClick(event) {
        // Do not follow link
        event.preventDefault();

        if (event.currentTarget.hash) {
            this.publish('anchor.scroll', {
                target: event.currentTarget.hash,
            });
        }
    }

    /**
     * Handles the event, when a slider box item is clicked
     * @param {Event} event The click event
     */
    onSliderBoxClick(event) {
        // mobile viewports have a simple scroll
        // md viewports have a click interaction
        if (this.breakpoint.isSM) {
            return;
        }

        // Get elements
        const $target = this.$(event.currentTarget);
        const $sliderSlide = $target.parent();
        const $slider = $target.parents('.js-slider');

        // Bring to front if in view already but not active yet
        if ($target.hasClass('is-active-before') && !$target.hasClass('is-active') && window.browser.isTouchDevice) {

            // Remove all other active classes
            $slider.find('.is-active').removeClass('is-active');

            // Add active class
            $target.addClass('is-active');
            $sliderSlide.addClass('is-active');


        // Activate elements which are not in view yet
        } else if (!$target.hasClass('is-active-before')) {

            // Remove all other active classes
            $slider.find('.is-active').removeClass('is-active');

            $target.addClass('is-active is-active-before');
            $sliderSlide.addClass('is-active');

            setTimeout(() => {
                // Set some item to be active next
                $slider.find('.js-slider-box:not(.is-active-before)').eq(0).addClass('is-active-next');
            }, 100);

        // Deactivate active elements
        } else {
            // Remove all other active classes
            $slider.find('.is-active').removeClass('is-active');

            // Remove all cards previously shown on the side
            $slider.find('.is-active-next').removeClass('is-active-next');

            // If card is in view already push to the side
            $target.removeClass('is-active-before').addClass('is-active-next');
        }
    }

    /**
     * Handles the event, when a slider box item is clicked
     * @param {Event} event The touchstart event
     */
    onSliderBoxTouch(event) {
        // Highlight cards for mobile
        if (!this.breakpoint.isSM) {
            return;
        }

        // Get elements
        const $target = this.$(event.currentTarget);
        const $sliderSlide = $target.parent();
        const $slider = $target.parents('.js-slider');

        // Remove all active classes
        $slider.find('.is-active').removeClass('is-active');

        // Set new active classes
        $target.addClass('is-active');
        $sliderSlide.addClass('is-active');
    }

    /**
     * Handles the event, when a slider box item is hovered
     * @param {Event} event The mouseenter event
     */
    onCardMouseenter(event) {
        // Only follow mouse on desktop
        if (this.breakpoint.isSM) {
            return;
        }

        // Get elements
        const $card = this.$(event.currentTarget);
        $card.addClass('is-moving');

        // Bring to front
        this.$('sliderSlide').removeClass('is-active');
        $card.parents('.js-slider-slide').addClass('is-active');
    }

    /**
     * Handles the event, when a slider box item is hovered
     * @param {Event} event The mousemove event
     */
    onCardMousemove(event) {
        // Only follow mouse on desktop
        if (this.breakpoint.isSM) {
            return;
        }

        // Get elements
        const $card = this.$(event.currentTarget);
        const cardOffset = $card.get(0).getBoundingClientRect();

        // define offset
        const y = (event.clientY - cardOffset.top - $card.outerHeight() / 2) * 0.33;
        const x = (event.clientX - cardOffset.left - $card.outerWidth() / 2) * 0.33;

        // Add offset
        $card.attr('style', '--offset-top: ' + y + 'px; --offset-left: ' + x + 'px;');
    }

    /**
     * Handles the event, when a slider box item is hovered
     * @param {Event} event The mouseleave event
     */
    onCardMouseleave(event) {
        // Only follow mouse on desktop
        if (this.breakpoint.isSM) {
            return;
        }

        // Get elements
        const $card = this.$(event.currentTarget);

        // Remove class
        $card.removeClass('is-moving');
    }

    /**
     * Renders dynamic elements on the page
     */
    render() {
        this.scrollTop = this.win.scrollTop();

        // Define body classes
        const bodyClasses = {
            // 'is-scrolled': this.scrollTop > 0,
            'is-scrolled-some': this.scrollTop > this.remToPx(2),
            'is-scrolled-decent': this.scrollTop > this.winH * 0.5,
            // 'is-scrolled-up': this.isScrollUp && this.scrollTop >= 0,
            // 'is-scrolled-up-decent': this.isScrollUpMuch && this.scrollTop >= 0,
        };

        // Apply body classes
        const classEntries = Object.entries(bodyClasses);
        const addClasses = classEntries.filter(([, active]) => active).map(entry => entry[0]).join(' ');
        const removeClasses = classEntries.filter(([, active]) => !active).map(entry => entry[0]).join(' ');
        this.body.addClass(addClasses).removeClass(removeClasses);

        // Rendering finished
        this.isRendering = false;

        // Publish state
        // this.publish('site.scroll', {
        //     scrollTop: this.scrollTop,
        // });
    }

    /**
     * Handles the resize event of the window
     * @param {object} event The resize or orientationchange event
     */
    onWindowResize(event) {
        // prevent resize event from firing on mobile safari address bar change
        if (((window.browser && !window.browser.isIos) || !window.browser)
            || event.type === 'orientationchange'
        ) {
            // render again
            this.render();
        }
    }

    /**
     * Callback for intersecting entries
     * @param {Element} element The element that is intersecting
     * @param {Object} payload Intersect payload containing entry and in view states
     */
    onIntersect(element, payload) {
        if (element.classList.contains('js-sticky')) {
            element.classList.toggle('is-stuck', payload.entry.intersectionRatio < 1);
        }

        if (!element.dataset || !element.dataset.intersectBodyCls || !element.dataset.intersectMinRatio) {
            return;
        }

        const bodyClass = element.dataset.intersectBodyCls;
        const minRatio = Number(element.dataset.intersectMinRatio);
        const winHeight = window.innerHeight;
        const intersectionBoundTop = winHeight * minRatio;
        const intersectionBoundBottom = winHeight - winHeight * (1 - minRatio);
        const {intersectionRect} = payload.entry;
        const intersecting = intersectionRect.top <= intersectionBoundTop && intersectionRect.bottom >= intersectionBoundBottom;

        // Toggle body class
        this.body.toggleClass(bodyClass, intersecting);
    }

    /**
     * Smooth scroll to element
     * @param anchor
     * @param scrollOffset
     */
    scrollToAnchor(anchor, scrollOffset = 0) {
        // Select section by selector
        const $navTarget = this.$(anchor);

        // Exit if anchor is not available
        if ($navTarget.length === 0) {
            return;
        }

        // Define scroll wrapper and position
        const targetRect = $navTarget.get(0).getBoundingClientRect();
        const scrollTarget = this.win.scrollTop() + targetRect.top - scrollOffset;

        // Scroll window or modal to element smoothly
        this.publish('site.anchorScrolling', true);
        this.body.addClass('show-new-section');

        window.scrollTo({
            top: scrollTarget,
        });

        // Listen for animationend before removing classes again
        setTimeout(() => {
            this.body.removeClass('show-new-section');
            this.publish('site.anchorScrolling', false);
        }, 550);

        // Set focus on element for screen readers
        $navTarget.get(0).focus({preventScroll: true});
    }
}
