import HumansUiComponent from '../humans/frontend/ui-component';

/**
 * Common component
 * @package humans/frontend
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class Common extends HumansUiComponent {
    /**
     * Current font size
     * @type {number|null}
     */
    currentFontSize = null;

    /**
     * Current window width
     * @type {number|null}
     */
    currentWindowWidth = null;

    /**
     * Current window height
     * @type {number|null}
     */
    currentWindowHeight = null;

    /**
     * @inheritdoc
     */
    registerEvents() {
        this.win.on('resize', this.recalcResizeValues.bind(this));
    }

    /**
     * Returns the current computed font size as set on documentElement
     * @return {number|null}
     */
    get fontSize() {
        if (!this.currentFontSize) {
            this.recalcResizeValues();
        }

        return this.currentFontSize;
    }

    /**
     * Returns the current width of the window
     * @return {number|null}
     */
    get winWidth() {
        if (!this.currentWindowWidth) {
            this.recalcResizeValues();
        }

        return this.currentWindowWidth;
    }

    /**
     * Returns the current height of the window
     * @return {number|null}
     */
    get winHeight() {
        if (!this.currentWindowHeight) {
            this.recalcResizeValues();
        }

        return this.currentWindowHeight;
    }

    /**
     * Recalcs the current viewport values on resize
     */
    recalcResizeValues() {
        this.currentFontSize = Number(this.html.css('fontSize').replace('px', ''));
        this.currentWindowWidth = window.innerWidth;
        this.currentWindowHeight = window.innerHeight;
    }

    /**
     * Returns the pixel value for the given rem value
     * @param {Number} remSize The rem value
     * @return {Number} The pixel value
     */
    remToPx(remSize) {
        return this.fontSize * remSize;
    }

    /**
     * Returns the rem value for the given pixel value
     * @param {Number} pxSize The pixel value
     * @return {Number} The rem value
     */
    pxToRem(pxSize) {
        return Math.round(pxSize / this.fontSize * 100) / 100;
    }
}
