// Default options
export const defaultOptions = {
    selector: '.js-intersect',
    threshold: Array.from(Array(21).keys()).map(i => i / 20), // 0.05 steps
    root: null,
    inViewClass: 'intersect-inview', // Attached, when at least 1px is in view
    inViewFullClass: 'intersect-inview--full', // Attached, when the element is full in view (Only when element is smaller than the viewport)
    inViewBeforeClass: 'intersect-inview--before', // Attached, when the element was in view before and is not anymore in view
    inFullViewBeforeClass: 'intersect-infullview--before', // Attached, when the element was in full view before and is not anymore in full view
    inViewEnteredClass: 'intersect-inview--entered', // Attached, when the element is OR was half in view
    outOfViewClass: 'intersect-outofview', // When element is not in view
    outOfViewTopClass: 'intersect-outofview--top', // When element left view at top
    outOfViewBottomClass: 'intersect-outofview--bottom', // When element left view at bottom
};

/**
 * Intersection Observer wrapper
 * @package humans/frontend
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class Intersect {
    /**
     * Reference of the elements
     * @type {NodeList}
     */
    elements = null;

    /**
     * Options for the intersection instance
     * @type {object}
     */
    options = null;

    /**
     * Reference of the intersection observer
     * @type {IntersectionObserver}
     */
    observer = null;

    /**
     * Optional callback to apply, if an entry is hit by intersection observer
     * @type {function}
     */
    callback = null;

    /**
     * Initializes the object
     * @param {string}    selector The selector to use
     * @param {Object=}   options  (Optional) Additional options to apply
     * @param {function=} callback (Optional) Callback function
     */
    constructor(selector, options, callback) {
        // Apply polyfill, if needed
        if (NodeList.prototype.forEach === undefined) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        // Merge options
        this.options = Object.assign({}, defaultOptions, options || {}, {selector: selector});

        // Callback
        this.callback = callback;

        // Init the observer
        this.initObserver();

        // Init related elements
        this.updateElements();
    }

    /**
     * Destroys the Humans Intersect
     */
    destroy() {
        // Unobserve elements
        this.elements.forEach((element) => {
            this.observer.unobserve(element);
            element.dataset.intersectIsObserved = '0';
        });

        // Disconnect and reset observer
        this.observer.disconnect();
        this.observer = null;
    }

    /**
     * Updates the intersection and tries to take records for the elements
     */
    update() {
        this.updateElements();
        this.getObserver().takeRecords().forEach(this.handleIntersectionEntry.bind(this));
    }

    /**
     * Updates the elements to observe
     */
    updateElements() {
        this.elements = document.querySelectorAll(this.getOption('selector'));

        this.elements.forEach((element) => {
            if (!element.dataset.intersectIsObserved || element.dataset.intersectIsObserved === '0') {
                this.observer.observe(element);
                element.dataset.intersectIsObserved = '1';
            }
        });
    }

    /**
     * Initializes the observer
     */
    initObserver() {
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
            threshold: this.getOption('threshold'),
            root: this.getObserverRoot(),
        });
    }

    /**
     * Triggered, once an element intersects with viewport
     * @param {IntersectionObserverEntry[]} entries  The list of intersection entries
     * @param {IntersectionObserver}        observer Reference of the observer that triggered the event
     */
    handleIntersection(entries, observer) {
        entries.forEach(this.handleIntersectionEntry.bind(this));
    }

    /**
     * Handles the intersection for a single intersection entry
     * @param {IntersectionObserverEntry} entry The intersection entry
     */
    handleIntersectionEntry(entry) {
        let element = entry.target;

        // Get out if we have no rootBounds
        if (!entry.rootBounds) {
            return;
        }

        let inView = entry.isIntersecting || entry.intersectionRatio > 0,
            inViewFull = inView && entry.intersectionRatio >= 1,
            outOfView = !entry.isIntersecting,
            outOfViewTop = outOfView && entry.boundingClientRect.bottom <= entry.rootBounds.top,
            outOfViewBottom = outOfView && entry.boundingClientRect.bottom > entry.rootBounds.top;

        // Toggle in view classes
        element.classList.toggle(this.getOption('inViewClass'), inView);
        element.classList.toggle(this.getOption('inViewFullClass'), inViewFull);

        // Toggle out of view classes
        // element.classList.toggle(this.getOption('outOfViewClass'), outOfView);
        // element.classList.toggle(this.getOption('outOfViewTopClass'), outOfViewTop);
        // element.classList.toggle(this.getOption('outOfViewBottomClass'), outOfViewBottom);

        // Add in view before class
        if (inView && !element.dataset.intersectInView) {
            element.dataset.intersectInView = '1';
        }

        // if (outOfView && element.dataset.intersectInView) {
        //     element.classList.add(this.getOption('inViewBeforeClass'));
        // }

        if (!inViewFull && element.dataset.intersectInView) {
            element.classList.add(this.getOption('inFullViewBeforeClass'));
        }

        // Add entered class
        // if (entry.intersectionRatio > 0.5 && element.dataset.intersectInView) {
        //     element.classList.add(this.getOption('inViewEnteredClass'));
        // }

        // Call callback with element and all states as object
        if (typeof this.callback === 'function') {
            this.callback(element, {
                entry,
                inView,
                inViewFull,
                outOfView,
                outOfViewTop,
                outOfViewBottom,
            });
        }
    }

    /**
     * Returns the instance of the observer
     * @return {IntersectionObserver} The observer instance
     */
    getObserver() {
        return this.observer;
    }

    /**
     * Returns the option value for the given key or null
     * @param {string} key The key for the option
     * @return {*|null} The value or null
     */
    getOption(key) {
        return this.options[key] || null;
    }

    /**
     * Returns a proper observer root
     * @return {Node|null} the observer root
     */
    getObserverRoot() {
        let root = this.getOption('root');

        // If root is null, default viewport is used
        if (root !== null) {
            root = typeof root === 'string' ? document.querySelector(root) : root;
        }

        return root;
    }
};
