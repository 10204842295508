import $ from 'cash-dom';
import PubSub from 'pubsub-js';
import throttle from 'lodash/throttle';

// Polyfill import's
import 'core-js/features/promise/index';
import 'core-js/features/promise/finally';

/**
 * Basic UI component
 * @package humans/frontend
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class UiComponent
{
    /**
     * Options for thew component
     * @type {Object}
     */
    options = {};

    /**
     * Reference of the main component
     * @type {Main|null}
     */
    main = null;

    /**
     * Reference of the common component
     * @type {Common}
     */
    common = null;

    /**
     * UI References for the component
     * @type {Object}
     */
    ui = {};

    /**
     * Base UI
     * @type {Object}
     */
    baseUi = {
        win: window,
        doc: window.document,
        body: 'body',
        html: 'html',
    };

    /**
     * DOM references for UI selectors
     * @type {Object}
     */
    domReferences = null;

    /**
     * Indicates if doc is ready
     * @type {boolean}
     */
    isDocReady = false;

    /**
     * Returns the DOM reference of the window
     * @returns {Cash} The reference for window
     */
    get window() {
        return this.$('win');
    }

    /**
     * Returns the DOM reference of the window
     * @returns {Cash} The reference for window
     */
    get win() {
        return this.$('win');
    }

    /**
     * Returns the DOM reference of the document
     * @returns {Cash} The reference for document
     */
    get doc() {
        return this.$('doc');
    }

    /**
     * Virtual body getter
     * @return {Cash} The reference for body
     */
    get body() {
        return this.$('body');
    }

    /**
     * Returns the DOM reference of the html element
     * @returns {Cash} The reference for html
     */
    get html() {
        return this.$('html');
    }

    /**
     * UI Component constructor
     * @param {Object=} options (Optional) Options to apply for the component
     */
    constructor(options = {}) {
        this.options = {...this.options, ...options};
        this.main = options.main;

        // Assign common component
        if (options.common) {
            this.common = options.common;
        }

        // Reset dom references
        this.resetDomReferences();

        // Set doc ready state
        this.$(() => {
            this.isDocReady = true;
        });

        // Bind ready and load event
        this.$(this.onReady.bind(this));
        this.win.on('load', this.onLoad.bind(this));
        this.subscribe('dom.update', this.throttle((topic, mutations) => {
            this.resetDomReferences();
            this.onDomUpdate(mutations);
        }, 100).bind(this));
    }

    /**
     * Method, that could be used by child classes for initialization
     */
    init() { /* Stub */ }

    /**
     * Method, that could be overwritten by inherited classes to register events
     */
    registerEvents() { /* Stub */ }

    /**
     * Method, that could be overwritten by inherited classes to execute code, once DOM is ready
     */
    onReady() { /* Stub */ }

    /**
     * Method, that could be overwritten by inherited classes to execute code, once Load event is triggered
     */
    onLoad() { /* Stub */ }

    /**
     * Returns a single Element for the given key or selector
     * @param {string|HTMLElement|Function|EventTarget|null} key Internal UI reference, selector, DOM Element or function
     * @param {boolean=} useCache Use cache for given selector?
     * @return {Cash} The element for the selector
     */
    $(key = null, useCache = true) {
        // No key given? Return Cash instance
        if (key === null) {
            return $;
        }

        // Return Cash instance with invoked key if we don't have string
        if (typeof key !== 'string') {
            return $(key);
        }

        let selector = key;

        if (this.ui[key]) {
            selector = this.ui[key];
        } else if (this.baseUi[key]) {
            selector = this.baseUi[key];
        }

        // Cache selector result
        if (!this.domReferences[key] || !useCache || !window.MutationObserver) {
            this.domReferences[key] = $(selector);
        }

        return this.domReferences[key];
    }

    /**
     * Returns the selector for the given key or null if no selector was defined for key
     * @param {string} key The key defined under UI
     * @return {null|string} The selector or null
     */
    sel(key) {
        return this.ui.hasOwnProperty(key) ? this.ui[key] : null;
    }

    /**
     * Triggered, when the DOM has been updated and mutation observer was required
     * @param {array} mutations Array with the mutations
     */
    onDomUpdate(mutations) { /* Stub: Implement logic in inherited components */ }

    /**
     * Unlinks all references on dom update
     */
    resetDomReferences() {
        this.domReferences = {};
    }

    /**
     * Creates a throttled function
     * @see https://lodash.com/docs/4.17.10#throttle
     *
     * @param {Function} func   The function to throttle.
     * @param {Number}   wait   The number of milliseconds to throttle invocations to;
     *                          if omitted, `requestAnimationFrame` is used (if available).
     * @param {Object=} options The options object.
     * @returns {Function} Returns the new throttled function.
     */
    throttle(func, wait, options) {
        return throttle(func, wait, options);
    }

    /**
     * Returns the pixel value for the given rem value
     * @param {Number} remSize The rem value
     * @return {Number} The pixel value
     */
    remToPx(remSize) {
        if (this.common) {
            return this.common.remToPx(remSize);
        }

        const fontSize = Number(this.html.css('fontSize').replace('px', ''));

        return fontSize * remSize;
    }

    /**
     * Scrolls the document to given destination
     * @deprecated Use native scroll functions
     */
    scrollTo() {
        throw new Error('scrollTo is deprecated use native smooth scroll instead')
    }

    /**
     * Returns the Pseudo DOM element for the given selector and part
     * @param {string=} selector (Optional) CSS selector to use [Default: body]
     * @param {string=} part (Optional) Pseudo part [Default: :after]
     * @returns {CSSStyleDeclaration|object} The style declaration
     */
    getPseudoElement(selector = 'body', part = ':before') {
        return window.getComputedStyle(document.querySelector(selector), part);
    }

    /**
     * General method to perform navigations from JS
     * @param {object} options Options for navigating
     */
    navigate(options) {
        const {url} = options;

        window.requestAnimationFrame(() => {
            window.location.href = url;
        });
    }

    /**
     * Publishes the topic, passing the data to it's subscribers
     * @param {string} topic The topic for publishing [Eg. history.push-state]
     * @param {*}      data  The data that should be published
     */
    publish(topic, data = null) {
        let publishTopic = topic.toLowerCase().replace(/\//g, '.');

        PubSub.publishSync(publishTopic, data);
    }

    /**
     * Subscribes the passed function to the passed topic.
     * Every returned token is unique and should be stored if you need to unsubscribe
     * @param {string} topic The topic to subscribe to
     * @param {Function} callback The function to call when a new message is published
     * @return {string} The token for the subscription
     */
    subscribe(topic, callback) {
        let subscribeTopic = topic.toLowerCase().replace(/\//g, '.');

        return PubSub.subscribe(subscribeTopic, callback);
    }
}
