// General deps
import $ from 'cash-dom';
import PubSub from 'pubsub-js';

// Humans lib deps
import HumansBrowserClasses from './humans/frontend/browser-classes';
import './humans/cash/mixins';

// Schekorr imports
import SchekorrCommon from './schekorr/common';
import SchekorrBreakpoint from './schekorr/breakpoint';
import SchekorrSite from './schekorr/site';

/**
 * Main entry point
 */
class Main {
    /**
     * List of available container
     * @type {Object}
     */
    container = {};

    /**
     * Reference of the browser classes
     * @type {HumansBrowserClasses|null}
     * /
     browserClasses = null;

    /**
     * List of ajax promises for preloaded content
     * @type {object}
     */
    prefetchPromises = {};

    /**
     * Constructor
     */
    constructor() {
        // Bind ready
        $(this.onReady.bind(this));

        // Add common container
        const common = this.addContainer('schekorr.common', new SchekorrCommon({main: this}));

        // Default JS modules
        this.addContainer('schekorr.breakpoint', new SchekorrBreakpoint({main: this, common}));
        this.addContainer('schekorr.site', new SchekorrSite({main: this, common}));

        // Start MutationObserver
        this.initMutationObserver();
    }

    /**
     * Initializes a global MutationObserver
     */
    initMutationObserver() {
        $(() => {
            if (window.MutationObserver) {
                const target = document.documentElement;
                const observer = new MutationObserver((mutations) => {
                    if (mutations.length) {
                        PubSub.publish('dom.update', mutations);
                    }
                });

                observer.observe(target, {childList: true, subtree: true, attributes: false});
            }
        });
    }

    /**
     * Adds a new container
     * @param {string} name   Name of the container
     * @param {Object} object The object
     * @return {object} The container
     */
    addContainer(name, object) {
        // Call init
        if ('init' in object && typeof object.init === 'function') {
            object.init.apply(object);
        }

        // Call register events
        if ('registerEvents' in object && typeof object.registerEvents === 'function') {
            object.registerEvents.apply(object);
        }

        this.container[name] = object;

        return this.container[name];
    }

    /**
     * Indicates if the container is available
     * @param {string} name The name of the container
     * @returns {boolean} True, if the container is available
     */
    hasContainer(name) {
        return this.container[name] && typeof this.container[name] === 'object';
    }

    /**
     * Returns the given container
     * @param {string} name The name of the container
     * @returns {*} The container object
     */
    getContainer(name) {
        return this.container[name];
    }

    /**
     * Triggered, once DOM is ready
     */
    onReady() {
        // Add browser classes
        this.browserClasses = new HumansBrowserClasses();

        // Dispatch app.ready event for custom HTML embed
        document.dispatchEvent(new CustomEvent('app.ready'));
    }
}

// Attach main instance to window
window.Main = new Main();
