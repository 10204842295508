import $ from 'cash-dom';

$.camelCase = (str) => {
    return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
};

$.isString = (x) => typeof x === 'string';

/**
 * Returns the scroll top value for the given element
 * @param {Number=} val (Optional) Scrolltop value to set
 * @return {undefined|Number} The scroll top value or undefined
 */
$.fn.scrollTop = function(val) {
    // Check if we have at least one element
    if (this.length === 0) {
        return undefined;
    }

    // Get first element of collection
    let element = this.get(0);

    let win;
    if (element.window) {
        win = element;
    } else if (element.nodeType === 9) { // Document element
        win = element.defaultView;
    }

    // Get proper var to use for indicating scrollTop value
    let prop = win ? 'pageYOffset' : 'scrollTop';

    // No val given -> return scrollTop value of element
    if (val === undefined) {
        return win ? win[prop] : element[prop];
    }

    if (win) {
        win.scrollTo(win.pageXOffset, val);
    } else {
        element[prop] = val;
    }
};

/**
 * Shorthand method to scroll an element into view
 * @param {string=} block    (Optional) The preferred positions of the element in viewport [start | center | end]
 * @param {string=} behavior (Optional) The scroll behavior [smooth | auto]
 */
$.fn.scrollIntoView = function({block = 'start', behavior = 'smooth'} = {}) {
    // Check if user prefers reduced motion
    const motionQuery = window.matchMedia('(prefers-reduced-motion)');
    if (motionQuery.matches) {
        behavior = 'auto';
    }

    this.get(0).scrollIntoView({behavior: behavior, block: block});
};

/**
 * Shorthand method to scroll an element to desired position
 * @param {number=}   top      (Optional) Specifies the number of pixels along the Y axis to scroll the window or element.
 * @param {number=}   left     (Optional) Specifies the number of pixels along the X axis to scroll the window or element.
 * @param {string=}   behavior (Optional) The scroll behavior [smooth | auto]
 * @param {function=} callback (Optional) Callback to apply when scrolling is finished
 */
$.fn.scrollTo = function({top = 0, left = 0, behavior = 'smooth', callback = null} = {}) {
    const scrollTop = this.scrollTop();

    // Handle special case, when site has bubbles
    if ($('body').hasClass('has-teaser-bubbles-inview') && this.get(0) === window) {
        const offset = Number(window.getComputedStyle(document.querySelector('.content-wrapper')).top.replace(/px$/, '')) * (-1);

        if (scrollTop > offset) {
            top -= scrollTop - offset;
        }
    }

    // Use value without decimal places
    top = Math.floor(top);

    // Check if user prefers reduced motion
    const motionQuery = window.matchMedia('(prefers-reduced-motion)');
    if (motionQuery.matches) {
        behavior = 'auto';
    }

    // Check if callback is given
    if (behavior === 'smooth' && callback && typeof callback === 'function') {
        const onScroll = () => {
            const scrollTop = this.get(0).scrollTop || this.get(0).pageYOffset;

            if (scrollTop === top) {
                this.get(0).removeEventListener('scroll', onScroll);
                callback();
            }
        };

        this.get(0).addEventListener('scroll', onScroll, {passive: true});
    }

    // Scroll to position
    this.get(0).scrollTo({
        top: top,
        left: left,
        behavior: behavior,
    });

    // If callback is given and no smooth scrolling, invoke callback anyway
    if (behavior !== 'smooth' && callback && typeof callback === 'function') {
        requestAnimationFrame(() => {
            callback();
        });
    }
};

/**
 * Overwrite current data behavior with old jquery/case 3.2 data behavior
 * @param {string=} name  (Optional) The data name to fetch/set
 * @param {mixed=}  value (Optional) The value to set for the given name
 * @return {mixed} The related data
 */
$.fn.data = function(name, value) {
    const dataNamespace = '__cashData';
    const dataAttributeRe = /^data-(.*)/;
    const getDataCache = (ele) => {
        return ele[dataNamespace] = (ele[dataNamespace] || {});
    };

    const getData = (ele, key) => {
        const cache = getDataCache(ele);

        if (key) {
            if (!(key in cache)) {
                let value = ele.dataset ? ele.dataset[key] || ele.dataset[$.camelCase(key)] : $(ele).attr(`data-${key}`);

                if (value !== undefined) {
                    try {
                        value = JSON.parse(value);
                    } catch (e) { }

                    cache[key] = value;
                }
            }

            return cache[key];
        }

        return cache;
    };

    const setData = (ele, key, value) => {
        getDataCache(ele)[key] = value;
    };

    if (!name) {
        // Get out if no element found
        if (!this[0]) {
            return;
        }

        // Loop over attributes
        $.each(this[0].attributes, (i, attr) => {
            const match = attr.name.match(dataAttributeRe);
            if (!match) {
                return;
            }

            this.data(match[1]);

        });

        return getData(this[0]);
    }

    if (typeof name === 'string') {
        if (value === undefined) {
            return this[0] && getData(this[0], name);
        }

        return this.each((idx, ele) => {
            setData(ele, name, value)
        });
    }

    for (let key in name) {
        this.data(key, name[key]);
    }

    return this;
};

/**
 * Method to select the text of a specific node
 */
$.fn.selectText = function() {
    if (this.length === 0) {
        return;
    }

    const node = this.get(0);

    if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
    }
};
